import React from 'react';
// import ToolbarSlot from './components/toolbarSlot/ToolbarSlot';
// import CommandBarSlot from "./components/commandBarSlot/CommandBarSlot";
import { AppHeader } from '@nike/eds';
import { NikeApp } from '@nike/nike-design-system-icons';

const Header = (props) => {
	return (
		<div>
			<AppHeader
				isDark
				logoSlot={<NikeApp color={'white'} width={'56px'} height={'64px'} />}
				appName="OSD AUDIT EVENT TOOL"
				// toolbarSlot={<ToolbarSlot />}
			/>
		</div>
	);
};

export default Header;
